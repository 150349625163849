.stay {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 4;
}

.MuiSpeedDial-root {
  top: 0.4vh;
  position: fixed;
  z-index: 40;

  button{
    min-width: 100px;
    min-height: 100px;
  }
}

.MuiSpeedDial-actions {
  button {
    min-width: 100px;
    min-height: 100px;
  }
}


@media (max-width: 1024px) {
  .MuiSpeedDial-root {
    top: 0.4vh;
  
    button{
      min-width: 30px;
      min-height: 30px;
    }
  }
}

// @media (max-width: 768px) {
//   .MuiSpeedDial-root {
//     top: 0.4vh;
  
//     button{
//       min-width: 20px;
//       min-height: 20px;
//     }
//   }
// }