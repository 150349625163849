.aboutme {
  font-size: 1.7rem;
}

.larger {
  font-size: 1.7rem;
}


.mainAbout {
  max-width: 100%;
  margin-top: 3em;
  margin-bottom: auto;
}

@media (max-width: 768px) {
  .about{
      max-width: 80%;
  }

  .larger {
      display: none;
  }
}

.about {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2vh;
}
.heading {
  width: 30%;
  font-size: 1.15rem;
}

.minHeading {
  font-size: 2rem;
  text-align: center;
  padding-top: 4vh;
  padding-bottom: 1.5vh;
  max-width: 70%;
  margin: auto;
}

.personalImg {
  margin: 2em;
  width: 35em;
  height: 20em;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
  overflow-y: hidden;
  max-width: 100%;
}

.meAndBoard {
  max-width: 100%;
  position: relative;
  top: -130px;
}

._content_wrapper {
  width: 40%;
}
.contact-btn {
  margin-right: 20px;
  text-decoration: none;
  color: #464646;
}
.contact-btn:hover {
  margin-right: 20px;
  text-decoration: none;
  background-color: #ec704c;
}

@media (max-width: 1400px) {
  .about {
    flex-direction: column;
  }
  ._img {
    width: 80%;
    height: max;
  }
  ._content_wrapper {
    width: 90%;
  }
  .contact_msg {
    display: none;
    // visibility: hidden;
  }
}

// css for slideshow

.slideshow {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  margin: auto; 
  margin-bottom: 5vh;
}

.each-slide-effect {
  height: 50vh;
  max-width: 100%;
  margin: auto;
  margin-bottom: 5vh;

  img {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    margin: auto;
  }

 
  div {
  background-repeat: no-repeat;
  background-position: 0%;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 5vh;
  height: 50vh;
  }
}


.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.hobbies {
  text-align: center;
}

@media (max-width: 900px) {
  .each-slide-effect {
    height: 50vh;
  }
}

@media (min-width: 2000px) {
  .each-slide-effect {
    div {
      height: 60vh;
    }
  }
}