/* for moving background */


.background {  
  position: absolute;
  top:0px; 
  bottom:-150vh; 
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

}

.circle{
  border-radius: 50%;
  background: rgb(255, 255, 255);
  animation: ripple 8s infinite;
  box-shadow: 0px 0px 2px 0px #508fb9;
}

.small{
  position: absolute;
  width: 10em;
  height: 10em;
  right: 20vw;
  top: 10vh;
  background: rgb(185, 92, 92);
}

@media (max-width: 1100px) {
  .small{
    width: 7em;
    height: 7em;
    right: 15vw;
    top: 30vh;
  }
}

@media (max-width: 768px) {
  .small{
    width: 4em;
    height: 4em;
    right: 20vw;
    top: 15vh;
  }
}

.mediun{
  position: absolute;
  width: 17em;
  height: 17em;
  right: 9vw;
  top: 15vh;
  background: rgb(105, 40, 121);
}

@media (max-width: 1100px) {
  .mediun{
    width: 11em;
    height: 11em;
    right: 6vw;
    top: 10vh;
  }
}

@media (max-width: 768px) {
  .mediun{
    width: 7em;
    height: 7em;
    right: -3vw;
    top: -1vh;
  }
}

.mediunLarge{
  position: absolute;
  width: 30em;
  height: 30em;
  right: 25vw;
  top: -5vh;
  background: rgb(207, 111, 8);
}

@media (max-width: 1100px) {
  .mediunLarge{
    width: 14em;
    height: 14em;
    right: 15vw;
    top: -10vh;
  }
}

@media (max-width: 768px) {
  .mediunLarge{
    position: absolute;
    width: 10em;
    height: 10em;
    right: 25vw;
    top: -7vh;
  }
}


.large{
  position: absolute;
  width: 32em;
  height: 32em;
  right: 40vw;
  top: -1vh;
  background: rgb(124, 162, 65);
}
@media (max-width: 1100px) {
  .large{
    width: 22em;
    height: 22em;
    right: 10vw;
    top: 10vh;
  }
}

@media (max-width: 768px) {
  .large{
    width: 7em;
    height: 7em;
    right: -10vw;
    top: 15vh;
  }
}

.xlarge{
  position: absolute;
  width: 40em;
  height: 40em;
  right: 2vw;
  top: 40vh;
  background: rgb(228, 205, 89);
}

@media (max-width: 1100px) {
  .xlarge{
    width: 35em;
    height: 35em;
    right: -22vw;
    top: -30vh;
  }
}

@media (max-width: 768px) {
  .xlarge{
    width: 15em;
    height: 15em;
    right: -50vw;
    top: -20vh;
  }
}

.xxlarge{
  position: absolute;
  width: 40em;
  height: 40em;
  right: 10vw;
  top: 10vh;
  background: rgb(148, 248, 139);
}

@media (max-width: 1100px) {
  .xxlarge{
    width: 33em;
    height: 33em;
    right: 30vw;
    top: -20vh;
  }
}

@media (max-width: 768px) {
  .xxlarge{
  width: 7em;
  height: 7em;
  right: 15vw;
  top: -4vh;
  }
}

.shade1{
  opacity: 0.3;
}

.shade2{
  opacity: 0.5;
  border: none;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  20%{
    transform: scale(1.2);
  }
  
  40%{
    transform: scale(0.8);
  }

  60%{
    transform: scale(1.2);
  }

  80%{
    transform: scale(0.6);
  }

  90%{
    transform: scale(0.7);
  }
  100%{
    transform: scale(0.8);
  }
}


/* CIRCLES HAPPENING AT DIFFERENT TIMES */

.circle2{
  position: absolute;
  border-radius: 50%;
  background: rgb(247, 171, 171);
  animation: oppRipple 12s infinite;
  box-shadow: 5px 5px 5px 5px #508fb9;
}

.oppLarge {
  width: 32em;
  height: 32em;
  right: 40vw;
  top: -1vh;
  background: rgb(124, 162, 65);
}

.oppLarge2 {
  width: 32em;
  height: 32em;
  right: -3vw;
  top: -8vh;
  background: rgb(223, 236, 205);
}

.oppMedium {
  width: 17em;
  height: 17em;
  right: 9vw;
  top: 15vh;
  background: rgb(105, 40, 121);
}

@media (max-width: 1100px) {
  .oppLarge {
    width: 22em;
    height: 22em;
    right: 10vw;
    top: 10vh;
  }

  .oppLarge2 {
    width: 15em;
    height: 15em;
    right: -10vw;
    top: -15vh;
  }

  .oppMedium {
    width: 17em;
    height: 17em;
    right: -17vw;
    top: 15vh;
  }
}

@media (max-width: 768px) {
  .oppLarge {
   display: none;
  }

  .oppLarge2 {
    width: 5em;
    height: 5em;
    right: -10vw;
    top: 48vh;
  }

  .oppMedium {
    width: 8em;
    height: 8em;
    left: -15vw;
    top: 60vh;
  }
}

@keyframes oppRipple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.4);
  }
  
  100%{
    transform: scale(0.8);
  }
}

/* Circles for down the page */

.circle3 {
  position: absolute;
  border-radius: 50%;
  background: rgb(247, 171, 171);
  animation: slowRipple 20s infinite;
  box-shadow: 1px 1px 1px 1px #508fb9;
}

@keyframes slowRipple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(2.4);
  }
  
  100%{
    transform: scale(0.8);
  }
}

.oppLarge3 {
  z-index: 1;
  width: 32em;
  height: 32em;
  right: -10vw;
  top: 110vh;
  background: rgb(123, 227, 250);
}

.oppLarge4 {
  width: 20em;
  height: 20em;
  left: -10vw;
  top: 90vh;
  background: rgb(205, 238, 246);
}

@media (max-width: 1100px) {
  .oppLarge3 {
    width: 15em;
    height: 15em;
    right: -10vw;
    top: 110vh;  }
    
  .oppLarge4 {
    width: 15em;
    height: 15em;
    left: -10vw;
    top: 110vh;  
  }
}

@media (max-width: 768px) {
  .oppLarge3 {
    width: 8em;
    height: 8em;
    right: -8vw;
    top: 200vh;
  }
  .oppLarge4 {
    width: 8em;
    height: 8em;
    left: -10vw;
    top: 150vh;
  }
}