nav {
  font-size: 2rem;
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  transform: rotate(-90deg) translate3d(-50%,0,0);
  top: 5%;
  left: -12rem;
  z-index: 5;

  a {
    margin-right: 2vw;
    text-decoration: none;
    color: #37290f;
  }

  a:hover {
    margin-right: 2vw;
    text-decoration: none;
    color: #c955f3;
  }

  a.active {
    color: #7f5e22;
    ;
  }
}

@media (max-width: 768px) {
  nav {
  font-size: 1rem;
  left: -5.5rem;
  }
}