.strip {
  background-color: rgba(0, 0, 0, 0.2);
  padding-bottom: 5vh;
  display: flex;
  height: 1.6vh;
  width: 100%;
  position: fixed;
  z-index: 0;
}

.Container {
  padding-top: 20vh;
  margin-left: 30%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;

  h5 {
    font-size: 12rem;
    margin: 10px;
  }
  h4 {
    font-size: 12rem;
    margin: 10px;
    margin-left: 0;
  }

  h1 {
    font-size: 8rem;
  }
}

@media (max-width: 900px) {
  .Container {
    padding-top: 10vh;
    margin-left: 20%;

    h4 {
      font-size: 8rem;
    }
    h5 {
      font-size: 8rem;
      margin: 10px;
    }

    h1 {
      font-size: 4.5rem;
    }
  }
}

.landContainer {
  height: 3vh;
  

  img {
    max-height: 100%;
  }
}

.rows {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  align-items: center
}

.landHeading {
width: fit-content;
height: fit-content;
}


.landHeading h5 {
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  letter-spacing: .15em;
  border-right: .07em solid black;
  animation: 
    printToScreen 3s steps(60, end),
    flash .8s step-end infinite;
}

@keyframes printToScreen {
  from { width: 0 }
  to { width: 100% }
}

@keyframes flash {
  from, to { border-color: transparent }
  50% { border-color: black }
}

@media (max-width: 768px) {
.heading {
  margin-left: spacing(10);
}}

@media (max-width: 768px) {
  .Container {
    h1 {
      font-size: 2.5rem;
    }
    h5 {
      font-size: 3rem;
    }
    h4 {
      font-size: 3rem;
      margin-left: 0;
    }
  }
}

