.projectContainer {
  text-align: center;
  background-color: aliceblue;
  margin-top: 20vh;
  padding-bottom: 5vh;

  .description {
    font-size: 2.2rem;
    display: flex;
    flex-direction: column;
  }

  .heading {
    text-align: center;
    margin: auto;
    font-size: 3rem;
    padding-top: 5vh;
    padding-bottom: 2vh;
  }

  img {
    max-width: 100%;
  }
}

.titles {
  text-align: center;
  margin-left: 10vw;
  margin-right: 10vw;
}

// Made and hosted:
.botLeft {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 8.5vw;
  font-size: 2rem;
  gap: 0;
  top: -10vh;
}

@media (max-width: 768px) {
  .botLeft {
    padding-left: 15.5vw;
    font-size: 1.3rem;
  }
}

.rotate {
  animation-name:rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate{
  100%{
    transform:rotate(360deg);
  }
}




.eachContainer {
  position: relative;
  z-index: 3;
  max-width: 65%;
  display: flex;
  flex-direction: row;
  background-color: rgba(133, 173, 175, 0.745);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 20px;
  margin: 15px auto;
  margin-bottom: 25px;
  justify-content: space-between;
  
  // flex-direction: row;

  .name {
    margin-top: 30px;
  }

  .leftDetails {
    font-size: 1.5rem;
    max-width: 95%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    p {
      padding-left: 4px;
      margin: 0;
      padding-bottom: 1vh;
    }
  }

  .icon {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 4;

    svg {
      color: #00132C;
      font-size:10vw;
    }

  }

  .rightContent {
    width: 55%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 60vh;
  }
}


.images {
  background-color: white;
  display: flex;
  justify-content: center;
  max-width: 100%;


  img {
    height: 50vh;
  }
}

.buttonsPro {
  display: flex;
  gap: 1vw;
  max-width: 80%;
}


@media (max-width: 1100px) {
  
  .eachContainer {
    .description {
      font-size: 1.5rem;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
    }
  }
  .titles {
    padding-top: 4vh;
    font-size: 1.5rem;
    margin-right: 5vw;
  }
}

@media (max-width: 768px) {
  .eachContainer {
    max-width: 65%;
    flex-direction: column;

    .git, .url {
      font-size: 0.6rem; 
      padding: 0; 
    }

    div {
      font-size: 1rem;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      height: 20vh;
      width: 100%;

      img{
        height: 20vh;
      }

      h2 {
        font-size: 1rem;
      }
    }

    .pad4 {
      font-size: 0.9rem;
    }

    .description {
      padding-top: 2vh;
      font-size: 0.8rem;
    }
  }
}

// .eachContainer::

.titles {
  padding-top: 4vh;
  font-size: 2.5rem;
}

@media (max-width: 768px) {
  .titles {
    font-size: 1.5rem;
  }
  .projectContainer {
    .heading { 
      font-size: 2rem;
    }
  }
}

// link CSS

.git {
  max-width: 45%;
  background-color:rgb(243, 248, 168);
  border: 1px solid #266DB6;
  color: #00132C;
  font-family: "Avenir Next LT W01 Bold",sans-serif;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;

}

.git:hover,
.git:active {
  outline: 0;
}

.git:hover {
  background-color: transparent;
  cursor: pointer;
}

.git:before {
  background-color: #D5EDF6;
  content: "";
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.git:hover:before {
  background-color: #6DCFF6;
}

@media (min-width: 768px) {
  .git {
  }
}

.url {
  max-width: 45%;
  background-color:rgba(163, 247, 150, 0.516);
  border: 1px solid #266DB6;
  color: #00132C;
  font-family: "Avenir Next LT W01 Bold",sans-serif;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;

}

.url:hover,
.url:active {
  outline: 0;
}

.url:hover {
  background-color: transparent;
  cursor: pointer;
}

.url:before {
  background-color: #D5EDF6;
  content: "";
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.url:hover:before {
  background-color: #6DCFF6;
}

@media (min-width: 768px) {
  .url {
  }
}

//modal close
.close {
  --b: 3px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: #373B44;
  
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.close:hover,
.close:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.close:active {
  background: var(--color);
  color: #fff;
}

.modalSplit{
  display: flex;
  justify-content: space-between
}